import { getOtherOptionsFromWindow } from "../../utils";
import { applyConsent } from "../applyConsent";
import { createTcfModelFromCookie, getUserDecision } from "@devowl-wp/cookie-consent-web-client";
import { getCookieConsentManager } from "..";

/**
 * When a content blocker needs to be unblocked (user click), then
 * we need to get the current consent, and add the newly consent cookies.
 */
async function adjustConsentComingFromContentBlocker(blocker) {
  // Get all available groups + current decision
  const {
    essentialGroup,
    groups,
    isTcf,
    tcf,
    tcfMetadata,
    userConsentCookieName
  } = getOtherOptionsFromWindow();
  const {
    id: blockerId,
    services,
    visualThumbnail
  } = blocker;
  const currentDecision = getUserDecision(userConsentCookieName);
  const [essentialGroupObj] = groups.filter(({
    slug
  }) => slug === essentialGroup); // Prepare new consent

  const newConsent = currentDecision === false ? {
    // The website owner uses a banner without overlay and content gets unblocked
    // We need to make sure, that also essential cookies got accepted
    groups: {
      [essentialGroupObj.id]: essentialGroupObj.items.map(({
        id
      }) => id)
    }
  } : {
    groups: currentDecision.consent
  };

  for (const {
    id: groupId,
    items
  } of groups) {
    for (const {
      id: cookieId
    } of items) {
      if (services.indexOf(cookieId) > -1) {
        var _newConsent$groups$gr;

        // Avoid duplicates
        if (((_newConsent$groups$gr = newConsent.groups[groupId]) === null || _newConsent$groups$gr === void 0 ? void 0 : _newConsent$groups$gr.indexOf(cookieId)) > -1) {
          continue;
        }

        newConsent.groups[groupId] = newConsent.groups[groupId] || [];
        newConsent.groups[groupId].push(cookieId);
      }
    }
  } // Keep TCF string as it is not touched for a content blocker


  let tcfString;

  if (process.env.IS_TCF === "1" && isTcf) {
    var _createTcfModelFromCo;

    tcfString = (_createTcfModelFromCo = createTcfModelFromCookie(tcf, tcfMetadata, getCookieConsentManager().getOption("tcfCookieName"))) === null || _createTcfModelFromCo === void 0 ? void 0 : _createTcfModelFromCo.tcfString;
  }

  await applyConsent({
    consent: newConsent,
    buttonClicked: "unblock",
    blocker: blockerId,
    blockerThumbnail: visualThumbnail !== null && visualThumbnail !== void 0 && visualThumbnail.embedId ? "".concat(visualThumbnail.embedId, "-").concat(visualThumbnail.fileMd5) : undefined,
    tcfString
  });
}

export { adjustConsentComingFromContentBlocker };