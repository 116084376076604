class BlockerBodyInlineStyles {
  static bodyContainer({
    layout: {
      type,
      dialogBorderRadius
    },
    design: {
      bg,
      borderWidth,
      borderColor
    },
    bodyDesign: {
      padding
    },
    customCss: {
      antiAdBlocker
    },
    showFooter
  }) {
    const style = {
      background: bg,
      padding: padding.map(p => "".concat(p, "px")).join(" "),
      borderRadius: !showFooter && type === "dialog" ? "0 0 ".concat(dialogBorderRadius, "px ").concat(dialogBorderRadius, "px") : undefined,
      lineHeight: 1.4,
      overflow: "auto" // See https://stackoverflow.com/a/27829422/5506547

    };

    if (type === "dialog" && borderWidth > 0) {
      style.borderLeft = "".concat(borderWidth, "px solid ").concat(borderColor);
      style.borderRight = style.borderLeft;

      if (!showFooter) {
        style.borderBottom = style.borderLeft;
      }
    }

    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-body-container",
      style
    };
  }

  static body({
    customCss: {
      antiAdBlocker
    }
  }) {
    const style = {
      margin: "auto"
    };
    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-body",
      style
    };
  }

  static description({
    design: {
      fontSize
    },
    bodyDesign: {
      descriptionInheritFontSize,
      descriptionFontSize
    },
    individualLayout: {
      descriptionTextAlign
    },
    customCss: {
      antiAdBlocker
    }
  }) {
    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-description",
      style: {
        marginBottom: 10,
        fontSize: descriptionInheritFontSize ? +fontSize : +descriptionFontSize,
        textAlign: descriptionTextAlign
      }
    };
  }

}

export { BlockerBodyInlineStyles };