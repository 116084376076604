import { findAndUnblock } from ".";
/**
 * Use this class as main entry for your unblocking mechanism.
 *
 * **Attention:** Please make sure you start the watcher at time where the `document.readyState`
 * is not already `complete`. Why? At this time, we can make sure that our `jQuery.fn.ready` and
 * other initiators works as expected.
 */

class UnblockWatcher {
  constructor(options) {
    this.interval = void 0;
    this.options = void 0;
    this.options = options;
  }

  unblockNow() {
    return findAndUnblock(this.options);
  }

  start() {
    clearInterval(this.interval);
    this.interval = setInterval(this.unblockNow.bind(this), 1000);
  }

  stop() {
    clearInterval(this.interval);
  }

}

export { UnblockWatcher };