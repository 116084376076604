import { isCurrentlyInTransaction, OPT_IN_CONTENT_BLOCKER_ALL } from "../..";
import { MEMORIZE_JQUERY_EVENT_PROPERTY } from ".";
const OVERWRITE_PROPERTY = "rcbJQueryEventListener";
/**
 * Overwrite `jQuery(selector).on` and `jQuery.event.add` for special events
 * like `elementor/frontend/init`.
 */

function applyJQueryEventInitiator(doc, element, eventName, {
  onBeforeExecute
} = {
  onBeforeExecute: undefined
}) {
  const overwriteProp = "".concat(OVERWRITE_PROPERTY, "_").concat(eventName);
  const memorizeProp = "".concat(MEMORIZE_JQUERY_EVENT_PROPERTY, "_").concat(eventName);
  const {
    jQuery
  } = doc.defaultView || doc.parentWindow;

  if (!jQuery) {
    return;
  }

  const {
    event,
    Event
  } = jQuery;

  if (!event || !Event || event[overwriteProp]) {
    return;
  }

  const {
    add
  } = event;
  Object.assign(event, {
    [overwriteProp]: true,
    add: function (...args) {
      // https://git.io/JsXSb
      const [elem, types, handler, data, selector] = args;
      const useTypes = Array.isArray(types) ? types : typeof types === "string" ? types.split(" ") : types;
      const memorizeExecutionPromise = event[memorizeProp];
      const inTransaction = isCurrentlyInTransaction(); // Redirect to own thread to avoid variable order lacks (e. g. Uncode Gmaps Integration, Contact Form 7)

      const executeHandle = ([, ...eventParameters] = []) => setTimeout(() => {
        onBeforeExecute === null || onBeforeExecute === void 0 ? void 0 : onBeforeExecute(inTransaction);
        handler === null || handler === void 0 ? void 0 : handler(new Event(), ...eventParameters);
      }, 0);

      if (types && elem === element) {
        for (const type of useTypes) {
          const isRequestedEventName = type === eventName;

          if (isRequestedEventName && inTransaction) {
            let executed = false;
            document.addEventListener(OPT_IN_CONTENT_BLOCKER_ALL, () => {
              // IE does not support the `once` option
              if (executed) {
                return;
              }

              executed = true;

              if (memorizeExecutionPromise) {
                memorizeExecutionPromise.then(executeHandle);
              } else {
                executeHandle();
              }
            });
          } else if (isRequestedEventName && memorizeExecutionPromise) {
            memorizeExecutionPromise.then(executeHandle);
          } else {
            add.apply(this, [elem, type, handler, data, selector]);
          }
        }
      } else {
        add.apply(this, args);
      }
    }
  });
}

export { applyJQueryEventInitiator };