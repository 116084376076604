import { detectLastClicked, WaitSynchronousScripts, setCurrentlyInTransaction, createVisual, probablyResetParentContainerForVisual, putScriptInlineToDom, findBlockedNodes, transformInlineStyleRules, transformToOriginalAttribute, HTML_ATTRIBUTE_BLOCKER_ID, HTML_ATTRIBUTE_COOKIE_IDS, HTML_ATTRIBUTE_BLOCKER_CONNECTED, HTML_ATTRIBUTE_INLINE, HTML_ATTRIBUTE_UNBLOCKED_TRANSACTION_COMPLETE, HTML_ATTRIBUTE_RESET_PARENT_IS_RATIO_CONTAINER, OPT_IN_CONTENT_BLOCKER_ALL, applyJQueryReadyInitiator, applyJQueryEventInitiator, applyNativeEventListenerInitiator, applyWindowOnloadInitiator, setLastClickedConnectedCounter, OPT_IN_CONTENT_BLOCKER, loadVideoSource, delegateClick, dispatchResizeEvent, isElementInViewport } from "..";

/**
 * Refresh the DOM content depending on acceptance. It covers the following things:
 *
 * - Get all available blocked content
 * - Unblock blocked content depending on acceptance
 * - All other blocked content gets a visual content-blocker (if possible)
 */
async function findAndUnblock({
  checker,
  visual,
  overwriteAttributeValue,
  transactionClosed,
  priorityUnblocked,
  customInitiators,
  delegateClick: delegateClickSelectors
}) {
  setCurrentlyInTransaction(true);
  const nodes = findBlockedNodes(checker);
  transformInlineStyleRules(checker); // A collection of all unblocked content for this "transaction"; so we can keep track a batch
  // of unblocked items to keep dependencies intact (e.g. Custom script is blocked and needs Google Maps
  // API do be available).

  const unblockedNodes = [];
  let foundAnyLastClicked;

  const unmount = element => {
    var _visual$unmount;

    visual === null || visual === void 0 ? void 0 : (_visual$unmount = visual.unmount) === null || _visual$unmount === void 0 ? void 0 : _visual$unmount.call(visual, element);
    probablyResetParentContainerForVisual(element, false);
    element.remove();
  }; // In some cases, through custom event triggers and unblocked scripts, HTML elements could be "recreated" in our DOM
  // without our changes to mark the DOM node as "complete". Lets find those nodes and mark them correctly.


  document.querySelectorAll("[".concat(HTML_ATTRIBUTE_BLOCKER_ID, "]:not(.rcb-content-blocker):not([").concat(HTML_ATTRIBUTE_COOKIE_IDS, "]):not([").concat(HTML_ATTRIBUTE_UNBLOCKED_TRANSACTION_COMPLETE, "])")).forEach(n => n.setAttribute(HTML_ATTRIBUTE_UNBLOCKED_TRANSACTION_COMPLETE, "1")); // Reset all calculated and memorized results of ratio container as the CSS styles could be changed again (`probablyResetParentContainerForVisual`)

  document.querySelectorAll("[".concat(HTML_ATTRIBUTE_RESET_PARENT_IS_RATIO_CONTAINER, "]")).forEach(n => n.removeAttribute(HTML_ATTRIBUTE_RESET_PARENT_IS_RATIO_CONTAINER));
  let previousPriority;

  for (const row of nodes) {
    const {
      consent,
      node,
      isVisualCb,
      blocker,
      priority
    } = row;

    if (consent) {
      // Got this node already be handled by another call?
      if (!node.hasAttribute(HTML_ATTRIBUTE_COOKIE_IDS)) {
        continue;
      } else if (isVisualCb) {
        unmount(node);
        continue;
      } // Allows to execute custom code when a given priority got completed


      if (previousPriority !== undefined && previousPriority !== priority) {
        priorityUnblocked === null || priorityUnblocked === void 0 ? void 0 : priorityUnblocked(unblockedNodes, previousPriority);
      }

      previousPriority = priority; // Immediate deactivate nodes for future unblocks

      node.removeAttribute(HTML_ATTRIBUTE_COOKIE_IDS);
      const connectedBlocker = node.getAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED);
      const isLastClicked = detectLastClicked(node);

      if (isLastClicked) {
        foundAnyLastClicked = row;
      } // Remove visual content blocker if not yet removed through above method


      if (connectedBlocker) {
        const contentBlockers = Array.prototype.slice.call(document.querySelectorAll(".rcb-content-blocker[consent-blocker-connected=\"".concat(connectedBlocker, "\"]")));

        for (const contentBlocker of contentBlockers) {
          unmount(contentBlocker);
        } // Also reset parent containers stylings for nodes which not successfully created
        // a visual content blocker (e.g. duplicate exists)


        probablyResetParentContainerForVisual(node, false);
      } // Overwrite global listeners so they get immediate executed


      const {
        ownerDocument
      } = node;
      const {
        defaultView
      } = ownerDocument;
      applyJQueryReadyInitiator(ownerDocument);
      applyJQueryEventInitiator(ownerDocument, defaultView, "load"); // $(window).load()

      applyJQueryEventInitiator(ownerDocument, ownerDocument, "ready"); // $(document).on("ready")

      applyNativeEventListenerInitiator(defaultView, "load");
      applyNativeEventListenerInitiator(ownerDocument, "DOMContentLoaded");
      applyWindowOnloadInitiator(ownerDocument);
      customInitiators === null || customInitiators === void 0 ? void 0 : customInitiators(ownerDocument, defaultView);
      const waitSynchronousScripts = new WaitSynchronousScripts(); // Activate node

      const hasInlineAttribute = node.hasAttribute(HTML_ATTRIBUTE_INLINE);
      const {
        performedClick,
        workWithNode
      } = await transformToOriginalAttribute({
        node,
        allowClickOverrides: hasInlineAttribute ? false : isLastClicked,
        onlyModifyAttributes: hasInlineAttribute,
        setVisualParentIfClassOfParent: visual === null || visual === void 0 ? void 0 : visual.setVisualParentIfClassOfParent,
        overwriteAttributeValue
      });

      if (hasInlineAttribute) {
        await putScriptInlineToDom(node);
      } else if (performedClick) {
        // Avoid auto replays between the same transaction
        setLastClickedConnectedCounter(undefined);
      }

      await Promise.all(waitSynchronousScripts.diff()); // Allow to detach and attach again to DOM so e.g. `MutationObservers` can handle the DOM as expected

      if (workWithNode.getAttribute("consent-redom")) {
        const {
          parentElement
        } = workWithNode;

        if (parentElement) {
          const idx = [...parentElement.children].indexOf(workWithNode);
          parentElement.removeChild(workWithNode);
          insertChildAtIndex(parentElement, workWithNode, idx);
        }
      }

      workWithNode.dispatchEvent(new CustomEvent(OPT_IN_CONTENT_BLOCKER, {
        detail: {
          blocker,
          gotClicked: isLastClicked
        }
      }));
      document.dispatchEvent(new CustomEvent(OPT_IN_CONTENT_BLOCKER, {
        detail: {
          blocker,
          element: workWithNode,
          gotClicked: isLastClicked
        }
      }));

      if (isLastClicked && delegateClickSelectors) {
        delegateClick(workWithNode, delegateClickSelectors);
      }

      unblockedNodes.push({ ...row,
        node: workWithNode
      });
    } else if (visual && !isVisualCb) {
      createVisual({
        node: row.node,
        blocker: row.blocker,
        ...visual
      });
    }
  } // This transaction is "complete"


  if (unblockedNodes.length) {
    // Definitely reset now our last clicked counter to avoid double auto plays
    if (foundAnyLastClicked) {
      setLastClickedConnectedCounter(undefined);
    } // Do this before the events below to keep the initiators intact (e.g. jQuery.fn.ready)


    setCurrentlyInTransaction(false);
    document.dispatchEvent(new CustomEvent(OPT_IN_CONTENT_BLOCKER_ALL, {
      detail: {
        unblockedNodes
      }
    }));
    unblockedNodes.forEach(({
      node
    }) => {
      node.setAttribute(HTML_ATTRIBUTE_UNBLOCKED_TRANSACTION_COMPLETE, "1");
      node.dispatchEvent(new CustomEvent(OPT_IN_CONTENT_BLOCKER_ALL, {
        detail: {
          unblockedNodes
        }
      }));
    }); // The initiators (e.g. jQuery.ready) are all loaded in a new "thread" with a `setTimeout`,
    // but we need to make sure this event is dispatched afterwards.

    setTimeout(() => {
      transactionClosed === null || transactionClosed === void 0 ? void 0 : transactionClosed(unblockedNodes);
      loadVideoSource(unblockedNodes);
      dispatchResizeEvent(); // Scroll to unblocked, clicked element automatically

      if (foundAnyLastClicked && !isElementInViewport(foundAnyLastClicked.node)) {
        foundAnyLastClicked.node.scrollIntoView({
          behavior: "smooth"
        });
      }
    }, 0);
  } else {
    setCurrentlyInTransaction(false);
  }
}

function insertChildAtIndex(container, child, index) {
  if (index >= container.children.length) {
    container.appendChild(child);
  } else {
    container.insertBefore(child, container.children[index]);
  }
}

export { findAndUnblock };