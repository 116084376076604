/* istanbul ignore file: we do not need to care about the entry point file as errors are detected through integration tests (E2E) */

/**
 * Entrypoint for plain website. This file is loaded on all pages.
 */
import { ready } from "@devowl-wp/cookie-consent-web-client";
import { memorizeJQueryEvent, hijackJqueryFn, hijackJqueryEach } from "@devowl-wp/headless-content-unblocker";
import { listenOptInForContentBlocker, overwriteElementorVideoApi } from "./others";
const hijackJqueryFns = ["fitVids", "mediaelementplayer", "prettyPhoto", "gMap", "wVideo", "aviaVideoApi", "magnificPopup"];
const hijackQueryEach = [// https://www.famethemes.com/themes/onepress/
".onepress-map", // HivePress
'div[data-component="map"]'];
listenOptInForContentBlocker();
hijackJqueryFn(hijackJqueryFns);
hijackJqueryEach(hijackQueryEach);
overwriteElementorVideoApi();
ready(() => {
  // jQuery fn cannot be ready yet, let's wait for DOM ready
  hijackJqueryFn(hijackJqueryFns);
  hijackJqueryEach(hijackQueryEach);
  memorizeJQueryEvent(document, document, "tve-dash.load"); // Thrive Leads

  memorizeJQueryEvent(document, document, "mylisting/single:tab-switched"); // My Listing theme

  memorizeJQueryEvent(document, document, "hivepress:init"); // HivePress
}, "interactive");