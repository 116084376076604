import { useState } from "react";
import { getOtherOptionsFromWindow } from "../utils";
import { adjustConsentComingFromContentBlocker } from "../others";
import { HTML_ATTRIBUTE_COOKIE_IDS } from "@devowl-wp/headless-content-unblocker";
import { getUserDecision } from "@devowl-wp/cookie-consent-web-client";
import { usePlainCss, BlockerContextFactory, Blocker } from "@devowl-wp/react-cookie-banner";
import { BannerProductionNotice } from ".";
/**
 * Simply connects a context store to the blocker itself. On the website itself
 * it should not rely
 */

const WebsiteBlocker = ({
  poweredLink,
  blocker,
  paintMode,
  setVisualAsLastClickedVisual
}) => {
  const {
    customizeValuesBanner: {
      layout,
      decision,
      legal,
      design,
      headerDesign,
      bodyDesign,
      footerDesign,
      texts,
      individualLayout,
      saveButton,
      group,
      individualTexts,
      customCss
    },
    pageIdToPermalink,
    consentForwardingExternalHosts,
    isTcf,
    isEPrivacyUSA,
    isAgeNotice,
    isListServicesNotice,
    groups,
    userConsentCookieName,
    bannerI18n,
    affiliate,
    isCurrentlyInTranslationEditorPreview,
    pageByIdUrl
  } = getOtherOptionsFromWindow();
  const userDecision = getUserDecision(userConsentCookieName); // Calculate a border color for the complete box as the standard box does not have one configured

  const overwriteDesign = {
    borderWidth: design.borderWidth || 1,
    borderColor: design.borderWidth === 0 ? headerDesign.borderWidth > 0 ? headerDesign.borderColor : footerDesign.borderWidth > 0 ? footerDesign.borderColor : design.fontColor : design.borderColor
  }; // Context values preparation

  const [contextValue] = useState({
    layout: { ...layout
    },
    decision: { ...decision
    },
    legal: { ...legal
    },
    design: { ...design,
      ...overwriteDesign
    },
    headerDesign: { ...headerDesign
    },
    bodyDesign: { ...bodyDesign
    },
    footerDesign: { ...footerDesign
    },
    texts: { ...texts
    },
    individualLayout: { ...individualLayout
    },
    saveButton: { ...saveButton
    },
    group: { ...group
    },
    individualTexts: { ...individualTexts
    },
    customCss: { ...customCss
    },
    productionNotice: h(BannerProductionNotice, null),
    pageIdToPermalink,
    consentForwardingExternalHosts,
    paintMode,
    pageByIdUrl,
    groups,
    poweredLink,
    isTcf,
    ePrivacyUSA: isEPrivacyUSA,
    ageNotice: isAgeNotice,
    listServicesNotice: isListServicesNotice,
    blocker,
    i18n: bannerI18n,
    keepVariablesInTexts: isCurrentlyInTranslationEditorPreview,
    affiliate,
    consent: {
      groups: { ...(userDecision === false ? {} : userDecision.consent)
      }
    },
    onUnblock: event => {
      adjustConsentComingFromContentBlocker(blocker);
      setVisualAsLastClickedVisual(event);
    }
  });
  usePlainCss([".elementor-background-overlay ~ [".concat(HTML_ATTRIBUTE_COOKIE_IDS, "] { z-index: 99; }")].join(""));
  const BlockerContext = BlockerContextFactory.Context();
  return h(BlockerContext.Provider, {
    value: contextValue
  }, h(Blocker, null));
};

export { WebsiteBlocker };