import _extends from "@babel/runtime/helpers/extends";
import { useBlocker, BlockerContentInlineStyles, BlockerHeaderInlineStyles, BlockerFooterInlineStyles, BlockerHeader, BlockerFooter, BlockerBody, useWaitElementInViewport } from "../../..";
import { useRef, Fragment } from "react";

const BlockerTextContainer = ({
  closeIcon
}) => {
  const blocker = useBlocker();
  const {
    showFooter,
    paintMode
  } = blocker;
  const ref = useRef(); // Lazy load background image

  const isBackgroundVisible = paintMode === "instantInViewport" || useWaitElementInViewport(ref);
  return h("div", _extends({}, BlockerContentInlineStyles.inner(blocker), {
    ref: ref
  }), isBackgroundVisible && h("div", BlockerContentInlineStyles.innerBackground(blocker)), h("div", BlockerContentInlineStyles.content(blocker), h(BlockerHeader, {
    closeIcon: closeIcon
  }), h("div", BlockerHeaderInlineStyles.headerSeparator(blocker)), h(BlockerBody, null), !!showFooter && h(Fragment, null, h("div", BlockerFooterInlineStyles.footerSeparator(blocker)), h(BlockerFooter, null))));
};

export { BlockerTextContainer };